import { Link } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

export default function NotFoundPage() {
    return(
        <Layout>
            <SEO title="404: Not found" />
            <div className="font-lora text-2xl">
                <div>{"Oops... You aren't supposed to be here"}</div>
                <Link href="/" className = "hover:text-red-dark">Go back to the home page</Link>
            </div>
        </Layout>
    )
}